import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import { Header, Footer, AttackDetails, GroupDetails, Homepage } from './Layouts'
import { Paper } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import { memberfulLogin, memberfulRefresh, apiLogin, extend, externalLink } from '../lib/methods';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {AuthContext} from './AuthContext';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#64b0e5'
    }
  },
});

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  
  constructor(props) {
    super(props);
    const { cookies } = props;
    const { code } = queryString.parse(document.location.search);
    this.state = { 
      auth: {
        memberful_code: code, 
        memberful_refresh_token: cookies.get('memberful_refresh_token') || null,
        aws_access_key_id: cookies.get('aws_access_key_id') || null,
        aws_secret_key: cookies.get('aws_secret_key') || null,
        aws_session_token: cookies.get('aws_session_token') || null,
        user_name: cookies.get('user_name') || null,
        authenticated: !(code) && cookies.get('memberful_refresh_token') ? true : false,
        error_message: null
      }
    };
    
    this.state.auth.logout = () => {
      [
        'memberful_refresh_token', 
        'aws_access_key_id',
        'aws_secret_key',
        'aws_session_token'
      ].forEach(name => {
        console.log("Removing cookie: " + name);
        cookies.remove(name);
      });
      
      this.setState({ 
        auth: {
          memberful_code: null, 
          memberful_refresh_token: null,
          aws_access_key_id: null,
          aws_secret_key: null,
          aws_session_token: null,
          user_name: null,
          authenticated: false,
          error_message: null
        }
      });
      
      window.location.href = "/";
    };

    
    console.log(this.state.auth);
    
    // If we just received a code, submit for a token. Else, use refresh token.
    if (this.state.auth.memberful_code) {
      console.log("Received OAuth Code: " + this.state.auth.memberful_code);
        
      memberfulLogin(document.location.href)
        .then((user) => {
          console.log(user);
          console.log("Received OAuth token: " + user.accessToken);
          
          cookies.set('memberful_refresh_token', user.refreshToken, {
            path: '/',
            secure: true,
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          });
          
          this.setState({
            auth: extend(this.state.auth, {
              memberful_refresh_token: user.refreshToken
            })
          });
          
          return user.accessToken;
        })
        .then((token) => this.load_aws_credentials(token))
        .catch((error) => {
          console.error('Error Retrieving Memberful Token: ' + error.message);
          
          this.setState({
            auth: extend(this.state.auth, {
              authenticated: false,
              error_message: 'Error Retrieving Memberful Token: ' + error.message
            })
          });
        });
    } else if (this.state.auth.memberful_refresh_token) {
      this.refresh_memberful_token();
      
      this.interval = setInterval(
        () => this.refresh_memberful_token(),
        10 * 60 * 1000 //10 minutes
      );
    }

  }
  
  refresh_memberful_token() {
    console.log("Refreshing Token");
    memberfulRefresh(this.state.auth.memberful_refresh_token)
      .then((token_data) => {
        console.log(token_data);
        console.log("Received OAuth token: " + token_data.access_token);
        
        return token_data.access_token;
      })
      .then((token) => this.load_aws_credentials(token));
  }
  
  load_aws_credentials(memberful_token) {
    const { cookies } = this.props;
    
    apiLogin(memberful_token)
      .then((user_data) => {
        let user_name = user_data.name;
        let aws_credentials = user_data.creds;
        console.log("Logged in " + user_name);
        console.log(aws_credentials);
          
        const options = {
          path: '/',
          secure: true,
          expires: new Date(aws_credentials['Expiration'])
        };
        
        cookies.set('aws_access_key_id', aws_credentials['AccessKeyId'], options);
        cookies.set('aws_secret_key', aws_credentials['SecretKey'], options);
        cookies.set('aws_session_token', aws_credentials['SessionToken'], options);
        cookies.set('user_name', user_name, options);
        
        this.setState({
          auth: extend(this.state.auth, {
            aws_access_key_id: aws_credentials['AccessKeyId'],
            aws_secret_key: aws_credentials['SecretKey'],
            aws_session_token: aws_credentials['SessionToken'],
            user_name: user_name,
            authenticated: true,
            error_message: null
          })
        });
      })
      .catch((error) => {
        console.error('API Login Error: ' + error.message);
        
        this.setState({
          auth: extend(this.state.auth, {
            authenticated: false,
            error_message: 'Could not retrieve AWS API credentials: ' + error.message
          })
        });
      });
  }
  
  render() {
  return (
      <AuthContext.Provider value={this.state.auth}>
        <ThemeProvider theme={theme}>
          <Paper>
            <Header />
              <Switch>
                <Route path="/" exact component={Homepage} />
                <Route path="/attack" component={AttackDetails} />
                <Route path="/group" component={GroupDetails} />
                <Route path="/auth" component={LoggingIn} />
                <Route component={NoMatch} />
              </Switch>
            <Footer />
          </Paper>
        </ThemeProvider>
      </AuthContext.Provider>
  )}
}
export default withCookies(App);

class LoggingIn extends Component {
  static contextType = AuthContext;
  render() {
    
    const paper_style = {
        padding: 15,
        paddingBottom: 30
    };
    
    if (this.context.error_message) {
      return (
        <Paper style={paper_style}>
          <Typography variant="h6">
            Login Error
          </Typography>
          
          <Typography variant="body1">
            {this.context.error_message}
          </Typography>
        </Paper>
      );
    } else {
      return (
        this.context.authenticated ? (
          <Redirect to="/" />
        ) : (
        
          <Paper style={paper_style}>
            <Typography variant="h6">
              Logging In ...
            </Typography>
            
            <LinearProgress />
          </Paper>
        )
      );
    }
  }
}

class NoMatch extends Component {
  render() {
    
    const paper_style = {
        padding: 15,
        paddingBottom: 30
    };
    
    return (
      <Paper style={paper_style}>
        <Typography variant="h6">
          Sorry, long form intelligence is not available on Boyd.AI.  Please visit {externalLink('https://OODAloop.com', 'OODAloop.com', '')} to read that report.
        </Typography>
      </Paper>
    );

  }
}
