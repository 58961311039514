import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { memberfulUrl } from '../../lib/methods';
import {AuthContext} from '../AuthContext';
import { Link } from 'react-router-dom';

import boyd_logo from './boyd-logo.png';

const styles = theme => ({
  root: {
    width: '100%'
  },
  appbar: {
    backgroundColor: '#000000',
    color: '#ffffff'
  },
  grow: {
          flexGrow: 1
  },
  
  [theme.breakpoints.down('sm')]: {
    logo: {
      maxWidth: 100,
      margin: 5
    },
    
    title: {
      display: 'none'
    },
  },
  [theme.breakpoints.up('md')]: {
    logo: {
      maxWidth: 250,
      margin: 10
    },
    
    title: {
      lineHeight: 1.2,
      marginLeft: 10
    },
    
  }
});

class SimpleAppBar extends Component {
  
  render() {
    const { classes } = this.props;
    let auth = this.context;
    
    let login_logout = auth.authenticated ? (
      <React.Fragment>
        <Typography variant="body1" color="inherit">
          Welcome, {auth.user_name}!{' '}
        </Typography>
          
        <Button color="inherit" onClick={auth.logout}>Logout</Button>
      </React.Fragment>
      ) : (
        <React.Fragment>
          <Button color="inherit" href={memberfulUrl()}>Login</Button>
          <Button color="inherit" href="https://www.oodaloop.com/subscribe/" target="_blank">Sign Up</Button>
        </React.Fragment>
      );
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar} color="primary">
          <Toolbar>
            <Link to={{pathname: '/'}}>
              <img className={classes.logo} src={boyd_logo} alt="Boyd.ai" />
            </Link>
            
            <div className={classes.grow}>
              <Typography className={classes.title} variant="h6" color="inherit">
                National Security Archives <br />
                Terrorism Knowledgebase
              </Typography>
            </div>
            
            {login_logout}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
SimpleAppBar.contextType = AuthContext;

export default withStyles(styles)(SimpleAppBar);
