import React, { Component } from 'react'
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({

  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'left',
    marginBottom: 10
  },
  
  textField: {
    margin: theme.spacing(1),
    flexGrow: 1,
    maxWidth: 600
  },
  
  group: {
    margin: theme.spacing(1)
  }

});

class searchInput extends Component {
  
  handleTermChange = searchTerm => event => {
    //this.props.handleStateUpdate({ searchTerm: event.target.value });
  }
  
  handleTypeChange = searchType => event => {
    this.props.handleStateUpdate({ searchType: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const searchTerm = this.searchInput.value;
    this.props.handleStateUpdate({ 
      searchTerm: searchTerm,
      page: 0
    });
  }

  render () {
    const { classes } = this.props;
    
    return (
      <form noValidate autoComplete="off" onSubmit={this.handleSubmit} >
        <div className={classes.container}>
          <TextField
            id="search"
            label="Search Database"
            type="search"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            onChange={this.handleTermChange().bind(this)}
            defaultValue={this.props.searchTerm}
            inputRef={el => this.searchInput = el}
          />
          <RadioGroup
            row
            aria-label="Search Type"
            name="search_type"
            className={classes.group}
            defaultValue={this.props.searchType}
            onChange={this.handleTypeChange().bind(this)}
          >
            <FormControlLabel value="attack" control={<Radio color="primary" />} label="Attacks" />
            <FormControlLabel value="group" control={<Radio color="primary" />} label="Groups" />
          </RadioGroup>
        </div>
       </form>
    );
  }
}

export default withStyles(styles)(searchInput);
