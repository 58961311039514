import React, { Component, Fragment } from 'react';
import queryString from 'query-string';
import { TableData, SearchInput } from './';
import { createBrowserHistory } from 'history';
import { apiSearch } from '../../lib/methods';
import {AuthContext} from '../AuthContext';

const history = createBrowserHistory();

class SearchBody extends Component {
  constructor(props) {
    super(props);
    const { search, type } = queryString.parse(document.location.search);
    this.state = { 
      urlSearchTerm: search,
      urlSearchType: type,
      searchTerm: '',
      searchType: 'attack',
      matches: [],
      page: 0,
      rowsPerPage: 10,
      loading: false,
      count: 0
    };
    
    // check if URL has search term if so pass term for apiSearchDB
    if (this.state.urlSearchTerm && this.state.urlSearchTerm !== '') {
      console.log("loaded search term from URL: " + this.state.urlSearchTerm);
      this.state.searchTerm = this.state.urlSearchTerm;
    }
    
    // check if URL has search type if so pass type for apiSearchDB
    if (this.state.urlSearchType && ['attack','group'].includes(this.state.urlSearchType) ) {
      console.log("loaded search type from URL: " + this.state.urlSearchType);
      this.state.searchType = this.state.urlSearchType;
    }

  }
  
  // handle state update
  handleStateUpdate(inputState){
    this.setState(inputState);
  }
  
  componentDidMount () {
    if (this.state.searchTerm !== '') {
      this.reload_table();
    }
  }
  
  componentDidUpdate (prevProps, prevState) {
    
    let reload = false;
    for ( let prop of ["searchTerm", "searchType", "page","rowsPerPage"] ) {
      if (this.state[prop] !== prevState[prop]) {
        reload = true;
      }
    }
    
    if ( reload ) {
      this.reload_table();
    }
  }
  
  reload_table () {
    this.setState({
      loading: true
    });
    
    history.push('/?' + queryString.stringify(
      {
        search: this.state.searchTerm,
        type: this.state.searchType
      }  
    ));
      
    let auth = this.context;
    
    apiSearch(auth, this.state.searchTerm, this.state.searchType, this.state.page, this.state.rowsPerPage).then(
      apiResponse => {
        this.setState({
          matches: apiResponse.matches,
          count: apiResponse.count,
          loading: false
        });
      }
    );
  }
  
  render() {
    const { loading, searchTerm, searchType, matches, page, rowsPerPage, count } = this.state;
  
    return (
  
        <Fragment>
  
        <hr/>
          <SearchInput 
            searchTerm={searchTerm} 
            searchType={searchType} 
            handleStateUpdate={this.handleStateUpdate.bind(this)} 
          />
          <TableData 
            searchTerm={searchTerm} 
            searchType={searchType} 
            matches={matches}
            page={page} 
            rowsPerPage={rowsPerPage}
            count={count} 
            loading={loading}
            handleStateUpdate={this.handleStateUpdate.bind(this)} 
          />
  
        </Fragment>
  
    );
  }
}
SearchBody.contextType = AuthContext;

export default SearchBody
