import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  root: {
    width: '100%'
  },
  footer: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 5
  },
  appbar: {
    backgroundColor: '#000000',
    color: '#ffffff'
  },
  footerlink: {
    textDecoration: 'none',
    color: theme.palette.primary
  }
});

class SimpleAppBar extends Component {
  
  render() {
    const { classes } = this.props;
    
    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar} color="primary">
          <Toolbar>
            
            <Typography className={classes.footer} variant="body2" color="inherit">
              The Boyd.AI database is operated by{' '}
              <Link href="https://www.ooda.com/" color="primary" className={classes.link} target="_blank" rel="noopener noreferrer">
                OODA LLC
              </Link>
              .
              <br />
              Access is restricted to OODA Network Members. 
            
              For information on becoming a member please{' '}
              <Link href="https://www.oodaloop.com/subscribe/" color="primary" className={classes.link} target="_blank" rel="noopener noreferrer">
                visit the subscription page
              </Link>
              .
            </Typography>
            
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleAppBar);
