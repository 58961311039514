import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './Components/App'
import 'typeface-roboto';
import 'typeface-courier-prime'
import { CookiesProvider } from 'react-cookie';

const app = (
  <CookiesProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CookiesProvider>
)
ReactDOM.render( app, document.getElementById('root'))
