let blacklist_group_ids = [
    615,
    9999
];

export const attack_detail_main = [
    {
        title: "Intended Target",
        data_field: "intended_target"
    },{
        title: "Ultimate Target",
        data_field: "ultimate_target"
    },{
        title: "Description",
        data_field: "description"
    },{
        title: "Historical Implications",
        data_field: "hist_impl"
    },{
        title: "Law Enforcement Response",
        data_field: "law_response"
    },{
        title: "International Response",
        data_field: "intl_response"
    },{
        title: "Resolution",
        data_field: "resolution"
    },{
        title: "Lesson Learned",
        data_field: "lesson_learned"
    },{
        title: "External News",
        data_field: "ext_news"
    },{
        title: "Bibliography",
        data_field: "biblio"
    }
    
];

export const attack_detail_sidebar = [
    {
        title: "Date",
        data_field: "date"
    },{
        title: "Location",
        data_field: "location"
    },{
        title: "Type",
        data_field: "type_name"
    },{
        title: "Primary Group",
        data_field: "group_primary_name",
        max_length: null,
        link_function: function (match) {
            let group_id = match.group_primary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    },{
        title: "Secondary Group",
        data_field: "group_secondary_name",
        max_length: null,
        link_function: function (match) {
            let group_id = match.group_secondary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    },{
        title: "Tertiary Group",
        data_field: "group_tertiary_name",
        max_length: null,
        link_function: function (match) {
            let group_id = match.group_tertiary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    },{
        title: "Terrorist Involved",
        data_field: "terrorist_involved"
    },{
        title: "External Aid",
        data_field: "extaid"
    },{
        title: "Casualties",
        data_field: "casualty_string"
    },{
        title: "Victims",
        data_field: "victims"
    },{
        title: "Property Damage",
        data_field: "damaged_prop"
    }
];