import React, { Component } from 'react'
import { apiGroupDetails } from '../../lib/methods'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
// import WorkIcon from '@material-ui/icons/Work';
// import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import LinearProgress from '@material-ui/core/LinearProgress';
import {group_detail_main, group_detail_sidebar} from '../../lib/group_config';
import {AuthContext} from '../AuthContext';

import DetailElement from './DetailElement';

const styles = {
  paper: {
    padding: 15,
    paddingRight: 25
  },
  
  root: {
    padding: 4
  },
  
  element_title: {
    marginTop: 15
  },
  title: {
    fontFamily: '"Courier Prime", "Courier", serif',
  },
  element: {
    fontFamily: '"Courier Prime", "Courier", serif',
  }
};
  
class GroupDetails extends Component {


  constructor(props) {
    super(props);
    this.state = {
      groupData: [],
      loading: false,
      recordID: props.location.pathname.replace('/group/', "")
    };
  }

  componentDidMount() {
    const { recordID } = this.state;
    console.log('inside componentDidMount');
    
    this.setState({
      loading: true
    });
    
    let auth = this.context;
    
    apiGroupDetails(auth, recordID).then(apiResponse => {
      console.log(apiResponse);
      this.setState({
        groupData: apiResponse,
        loading: false
      });
    });
  }
  
  
  
  
  render() {
    console.log('inside render');
    
    const { groupData, loading } = this.state;
    const { classes } = this.props;
    
    if (loading) {
      return (
         <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="overline" display="block" gutterBottom>
                  <Link to={{pathname: '/'}}>Home</Link> >> Group Details
                </Typography>
                
                <LinearProgress />
                
                <Divider />
  
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    } else if (groupData) {
    
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="overline" display="block" gutterBottom>
                  <Link to={{pathname: '/'}}>Home</Link> >> Group Details
                </Typography>
                
                <Typography variant="h4" className={classes.title} gutterBottom>
                  {groupData.grpname}
                </Typography>
                
                <Divider />
  
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.paper}>
                {group_detail_main.map((element, index) => {
                  return (
                    <DetailElement
                      key={index}
                      element={element}
                      elementData={groupData}
                    />
                  );
                })}
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                 {group_detail_sidebar.map((element, index) => {
                  return (
                    <DetailElement
                      key={index}
                      element={element}
                      elementData={groupData}
                    />
                  );
                })}
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
      
    } else {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="overline" display="block" gutterBottom>
                  <Link to={{pathname: '/'}}>Home</Link> >> Group Details
                </Typography>
                
                <Typography variant="h6">
                  No Result Found.
                </Typography>
  
              </Paper>
            </Grid>
          </Grid>
        </div>  
      );
    }
  }
}

GroupDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

GroupDetails.contextType = AuthContext;

export default withStyles(styles)(GroupDetails);
