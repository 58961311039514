import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import {AuthContext} from '../AuthContext';
import {SearchBody} from '.';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {externalLink} from '../../lib/methods';

import boyd from './boyd.png';

const styles = theme => ({
  paper: {
    padding: 25,
    overflow: 'auto'
  },
  boyd: {
    float: 'right',
    filter: 'grayscale(100%)',
    margin: 20,
    marginTop: 5,
    marginRight: 10,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    maxWidth: '50%'
  },
  bodytext: {
    fontFamily: '"Courier Prime", "Courier", serif',
    lineHeight: 1.4
  }
});

class Homepage extends Component {
  static contextType = AuthContext;
  render() {
    
    const { classes } = this.props;
    
    return (
      this.context.authenticated ? (
        <SearchBody />
      ) : (
      
        <Paper className={classes.paper}>
          
          <img className={classes.boyd} src={boyd} alt="Boyd.ai" />
          
          <Typography className={classes.bodytext} variant="body1" gutterBottom>
            Boyd.AI is a fully indexed and searchable database of historical terrorist attacks and terrorist and threat group profiles.  It contains original analysis on over 10,000 terrorist attacks and profiles on over 500 threat groups.  This original research was compiled by the Terrorism Research Center over the course of 15 years and was recently made available to OODA Network Members via this site. For more information on becoming a member, please visit {externalLink('https://www.oodaloop.com/subscribe/','our subscription site')}.  OODA Network Members also get full access to the over 10,000 pieces of original intelligence reporting and 125,000 news brief summaries via the {externalLink('https://OODALoop.com','OODALoop.com')} website.
          </Typography>
          
          <Typography className={classes.bodytext} variant="body1" gutterBottom>
            To track threat issues on a daily basis, we recommend you subscribe to the {externalLink('https://www.oodaloop.com/ooda-daily-pulse-report/','OODA Daily Pulse Report')}, which provides a detailed summary of the top cybersecurity, technology, and global risk stories of the day. 
          </Typography>
          
          <Typography className={classes.bodytext} variant="body1" gutterBottom>
            This site is named in honor of Colonel John Boyd, who was a United States Air Force fighter pilot and Pentagon consultant of the late 20th century, whose theories have been highly influential in the military, business, and technology domains.  To learn more about John Boyd, please visit his {externalLink('https://en.wikipedia.org/wiki/John_Boyd_(military_strategist)','Wikipedia page')}.
          </Typography>
          
          
        </Paper>
      )
    );
  }
}

export default withStyles(styles)(Homepage);
