export const group_detail_main = [
    {
        title: "Overview",
        data_field: "grpoverview"
    },{
        title: "Narrative",
        data_field: "narrative"
    },{
        title: "Activities",
        data_field: "activities"
    },{
        title: "Key People",
        data_field: "people"
    }
    
];

export const group_detail_sidebar = [
    {
        title: "Status",
        data_field: "active"
    },{
        title: "Operating Region",
        data_field: "opregion"
    },{
        title: "Strength",
        data_field: "strength"
    },{
        title: "External Aid",
        data_field: "extaid"
    }
];