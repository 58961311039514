import React from 'react';
import Link from '@material-ui/core/Link';

//get correct URL for API endpoint
export const getConfig = () => {
  if ( window.location.hostname === 'boyd.ai' || window.location.hostname === 'www.boyd.ai' ) {
    //prod
    return {
      api_endpoint: 'https://prod.api.boyd.ai',
      api_region: 'us-east-2',
      memberful: {
        site: 'https://ooda.memberful.com',
        app_id: 'b1V8nhC5D267RsgWVKQNcPzm',
        redirect: 'https://boyd.ai/auth'
      }
    };
  } else if ( window.location.hostname === 'beta.boyd.ai') {
    //beta
    return {
      api_endpoint: 'https://beta.api.boyd.ai',
      api_region: 'us-east-2',
      memberful: {
        site: 'https://ooda.memberful.com',
        app_id: 'XAhYZD68RMXRAMMWzQUYuvRp',
        redirect: 'https://beta.boyd.ai/auth'
      }
    };
  } else {
    //other
    return {
      api_endpoint: 'https://beta.api.boyd.ai',
      api_region: 'us-east-2',
      memberful: {
        site: 'https://seantest.memberful.com',
        app_id: 'PnhYGdQRm1TakGWFW9kmPA7B',
        redirect: 'https://30c77e54927342b4b9508ff180e159a8.vfs.cloud9.us-east-2.amazonaws.com/auth'
      }
    };
  }
};


let ClientOAuth2 = require('client-oauth2');
let memberful_auth = new ClientOAuth2({
  clientId: getConfig().memberful.app_id,
  accessTokenUri: getConfig().memberful.site + '/oauth/token',
  authorizationUri: getConfig().memberful.site + '/oauth',
  redirectUri: getConfig().memberful.redirect
});

export function memberfulUrl(){
  return memberful_auth.code.getUri();
}

export function memberfulLogin(response_url) {
  //returns user object with token
  return memberful_auth.code.getToken(response_url);
}

export function memberfulRefresh(refresh_token) {
  /*global fetch*/
  /*global URLSearchParams*/
  
  // Build formData object.
  let formData = new FormData();
  formData.append('client_id', getConfig().memberful.app_id);
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refresh_token);

  return fetch(getConfig().memberful.site + '/oauth/token', {
    method: 'post',
    body: new URLSearchParams(formData)
  }).then((response) => {
    return response.json();
  });
}

//Generate configured API Gateway Client
function apiGatewayClient(auth) {
  let apigClientFactory = require('aws-api-gateway-client').default;
  
  const config = auth ? {
    invokeUrl: getConfig().api_endpoint,
    accessKey:auth.aws_access_key_id,
    secretKey: auth.aws_secret_key,
    sessionToken: auth.aws_session_token,
    region: getConfig().api_region,
  } : {
    invokeUrl: getConfig().api_endpoint,
  };

  return apigClientFactory.newClient(config);
}

//Login with memberful token and get back user name and AWS creds
export function apiLogin(memberful_token){
  
  let params = {};
  let pathTemplate = '/login';
  let method = 'POST';
  let additionalParams = {};
  let body = {
        token: memberful_token
    };
  
  return apiGatewayClient().invokeApi(params, pathTemplate, method, additionalParams, body)
    .then((response) => {
      return response.data;
    }).catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        
        return Promise.reject({message: error.response.data});
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({message: 'The API Login Failed'});
      }
    });
    
}

//get a single Group record from AWS API Gateway
export function apiGroupDetails(auth, group_id){
  
  let params = {};
  let pathTemplate = '/group';
  let method = 'GET';
  let additionalParams = {
    queryParams: {
        id: group_id
    }
  };
  
  return apiGatewayClient(auth).invokeApi(params, pathTemplate, method, additionalParams)
    .then(function(result){
        //This is where you would put a success callback
        console.log('apiGroupDetails Returned.');
        console.log(result.data);
        return result.data;
    }).catch( function(result){
        //This is where you would put an error callback
        return null;
    });
    
}

//get a single Attack record from AWS API Gateway
export function apiAttackDetails(auth, attack_id){
  
  let params = {};
  let pathTemplate = '/attack';
  let method = 'GET';
  let additionalParams = {
    queryParams: {
        id: attack_id
    }
  };
  
  return apiGatewayClient(auth).invokeApi(params, pathTemplate, method, additionalParams)
    .then(function(result){
        //This is where you would put a success callback
        console.log('apiAttackDetails Returned.');
        console.log(result.data);
        return result.data;
    }).catch( function(result){
        //This is where you would put an error callback
        return null;
    });
    
}

//search Attack records from AWS API Gateway
export function apiSearch(auth, searchTerm, searchType, page, rowsPerPage) {
  
  let params = {};
  let pathTemplate = '/' + searchType;
  let method = 'GET';
  let additionalParams = {
    queryParams: {
      search: searchTerm,
      page: Number(page),
      RPP: Number(rowsPerPage)
    }
  };
  
  return apiGatewayClient(auth).invokeApi(params, pathTemplate, method, additionalParams)
      .then(function(result){
          //This is where you would put a success callback
          console.log("apiSearch returned");
          console.log(result.data);
          return result.data;
      });
}

//truncate a string at word boundary
export function truncateString(inputString, maxLength) {
  
  if ( !(maxLength > 0) || (String(inputString).length <= maxLength) ) {
    return inputString;
  } else {
    //trim the string to the maximum length
    let trimmedString = inputString.substr(0, maxLength);
    
    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
    
    if (trimmedString.length < inputString.length) {
      trimmedString += ' ...';
    }
    
    return trimmedString;
  }
}

//extend an object
export function extend(obj, src) {
    for (var key in src) {
        if (src.hasOwnProperty(key)) obj[key] = src[key];
    }
    return obj;
}

export function externalLink(url, text, className) {
  return (
    <Link href={url} color="primary" className={className} target="_blank" rel="noopener noreferrer">
      {text}
    </Link>
    );
}

