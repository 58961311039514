let blacklist_group_ids = [
    615,
    9999
];

export const attackTableColumns = [
    {
        title: "Date",
        data_field: "date",
    },{
        title: "Headline",
        data_field: "headline",
        tooltip_field: "description",
        link_function: function (match) {
            return `/attack/${ match.attackid }`;
        }
    },{
        title: "Type",
        data_field: "type_name",
    },{
        title: "Casualties",
        data_field: "casualties",
        tooltip_field: "victims"
    },{
        title: "Primary Group",
        data_field: "group_primary_name",
        link_function: function (match) {
            let group_id = match.group_primary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    },{
        title: "Secondary Group",
        data_field: "group_secondary_name",
        link_function: function (match) {
            let group_id = match.group_secondary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    },{
        title: "Tertiary Group",
        data_field: "group_tertiary_name",
        link_function: function (match) {
            let group_id = match.group_tertiary;
            if ( blacklist_group_ids.includes(group_id) ) {
                return null;
            } else {
                return `/group/${ group_id }`;
            }
        }
    }
    
];

export const groupTableColumns = [
    {
        title: "Name",
        data_field: "grpname",
        tooltip_field: "narrative",
        link_function: function (match) {
            return `/group/${ match.grpid }`;
        }
    },{
        title: "Status",
        data_field: "active"
    },{
        title: "Operating Region",
        data_field: "opregion",
        tooltip_field: "opregion",
        max_length: 100
    },{
        title: "Strength",
        data_field: "strength",
        tooltip_field: "strength",
        max_length: 100
    },{
        title: "Key People",
        data_field: "people",
        tooltip_field: "people",
        max_length: 100
    }
    
];